import { Text } from '@iheartradio/web.accomplice/text';
import { ArrowLeftIcon, Box, Button } from '@iheartradio/web.companion';
import { Link } from '@remix-run/react';

import { useClick } from '~app/analytics/use-click';
import { useGetPageName } from '~app/hooks/use-get-page-name';

export function FooterBackToIheart() {
  const { onAnalyticsClick } = useClick();
  const pageName = useGetPageName();

  return (
    <Box paddingBottom="$16">
      <Text
        as="p"
        css={{ paddingBottom: '$16' }}
        kind={{ mobile: 'subtitle-3', large: 'subtitle-2' }}
      >
        Not ready for the new iHeart experience?
      </Text>
      <Button
        as={Link}
        color="red"
        data-test="backToIheart"
        kind="primary"
        onClick={() => {
          onAnalyticsClick(pageName, 'footer|go-to-current-iHeart');
        }}
        size={{ '@initial': 'small', '@large': 'large' }}
        to="/try-out-listen"
      >
        <ArrowLeftIcon />
        Go to current iHeart
      </Button>
    </Box>
  );
}
