import { Player } from '@iheartradio/web.companion';
import { Playback } from '@iheartradio/web.playback';
import { isNull } from '@iheartradio/web.utilities';
import { Link as RouterLink } from '@remix-run/react';

import { useIsTouch } from '~app/hooks/use-is-touch';
import {
  buildArtistUrl,
  buildLiveUrl,
  buildSongUrl,
} from '~app/utilities/urls';

import { AddToPlaylist } from '../controls/add-to-playlist';
import { playback } from '../playback';

export function LiveMetadata() {
  const metadata = playback.useMetadata();
  const { station } = playback.useState();
  const { isTouch } = useIsTouch();

  if (isNull(station) || isNull(metadata)) {
    return null;
  }

  const {
    artistId,
    artistName,
    description,
    id,
    name,
    subtitle,
    title,
    trackId,
  } = metadata.data;

  const liveStationUrl = buildLiveUrl({ id, name });

  if (metadata.type === Playback.MetadataType.Station) {
    return (
      <>
        <Player.MetadataText>
          <Player.Subtitle>
            {isTouch ?
              subtitle
            : <Player.Link
                as={RouterLink}
                data-test="fallback-subtitle-link"
                to={liveStationUrl}
                underline="hover"
              >
                {subtitle}
              </Player.Link>
            }
          </Player.Subtitle>
          <Player.Description>
            {isTouch ?
              description
            : <Player.Link
                as={RouterLink}
                data-test="fallback-description-link"
                to={liveStationUrl}
                underline="hover"
              >
                {description}
              </Player.Link>
            }
          </Player.Description>
        </Player.MetadataText>
      </>
    );
  }

  return (
    <>
      <Player.MetadataText>
        <Player.Subtitle>
          {isTouch ?
            subtitle
          : <Player.Link
              as={RouterLink}
              data-test="subtitle-link"
              to={liveStationUrl}
              underline="hover"
            >
              {subtitle}
            </Player.Link>
          }
        </Player.Subtitle>
        <Player.Title>
          {!isTouch && trackId && artistId ?
            <Player.Link
              as={RouterLink}
              data-test="title-link"
              to={buildSongUrl({
                artist: { id: artistId, name: artistName ?? '' },
                track: { id: trackId, name: title ?? '' },
              })}
              underline="hover"
            >
              {title}
            </Player.Link>
          : title}
        </Player.Title>
        <Player.Description>
          {!isTouch && artistId ?
            <Player.Link
              as={RouterLink}
              data-test="description-link"
              to={buildArtistUrl({
                id: artistId,
                name: artistName ?? '',
              })}
              underline="hover"
            >
              {description}
            </Player.Link>
          : description}
        </Player.Description>
      </Player.MetadataText>
      {Number(trackId) > 0 && <AddToPlaylist />}
    </>
  );
}
