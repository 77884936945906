import { Playback } from '@iheartradio/web.playback';
import { uuid } from '@iheartradio/web.utilities';
import { memo, useEffect } from 'react';

import { useConfig } from '~app/contexts/config';
import { useUser } from '~app/contexts/user';
import { useRootLoaderData } from '~app/hooks/use-root-loader-data';
import { playback } from '~app/playback/playback';

import { Adobe } from './adobe';
import { Braze } from './braze';
import { Comscore } from './comscore';
import { useAnalytics } from './create-analytics';
import { Epsilon } from './epsilon';
import { Igloo } from './igloo';

export const Analytics = memo(function Analytics({
  referer,
}: {
  referer?: string;
}) {
  const { geolocation, appVersion } = useRootLoaderData();
  const analytics = useAnalytics();
  const config = useConfig();
  const playbackState = playback.useState();
  const user = useUser();

  useEffect(() => {
    if (!user || analytics.initialized) return;

    analytics.initialize({
      // TODO: Most of the hardcoded values need to be updated at some point.
      device: {
        appVersion,
        id: uuid(),
        lat: geolocation.lat,
        lng: geolocation.lng,
        host: config.environment.hosts.listen,
        isPlaying: playbackState.status === Playback.Status.Playing,
        volume: Math.round(playbackState.volume),
        referer,
        env: 'listen',
      },
      user: {
        abTestGroup:
          user.abTestGroups &&
          Object.entries(user.abTestGroups).map(
            ([key, value]) => `${key}|${value}`,
          ),
        genreIsDefault: false,
        genreSelected: [],
        isTrialEligible: user.subscription?.isTrialEligible ?? false,
        privacyOptOut: user.privacy?.hasOptedOut ?? false,
        profileId: user.profileId.toString(),
        registration: {
          birthYear: user.birthYear,
          country: user.country,
          gender: user.gender,
          type: user?.oauths?.[0]?.type ?? 'IHR',
          zip: user.zipCode,
        },
        subscriptionTier: user.subscription?.type ?? 'NONE',
        skuPromotionType: user.subscription?.productId,
      },
    });
  }, []);

  return (
    <>
      <Comscore />
      <Igloo />
      <Epsilon />
      <Adobe />
      <Braze />
    </>
  );
});
