import { Player } from '@iheartradio/web.companion';
import { Playback } from '@iheartradio/web.playback';
import { isNull } from '@iheartradio/web.utilities';

import { playback } from '../playback';
import { AlbumControls } from './album-controls';
import { ArtistControls } from './artist-controls';
import { FavoritesControls } from './favorites-controls';
import { LiveControls } from './live-controls';
import { PlaylistControls } from './playlist-controls';
import { PlaylistRadioControls } from './playlist-radio-controls';
import { PodcastControls } from './podcast-controls';
import { TopSongsControls } from './top-songs-controls';

const controls = {
  [Playback.StationType.Album]: AlbumControls,
  [Playback.StationType.Artist]: ArtistControls,
  [Playback.StationType.Favorites]: FavoritesControls,
  [Playback.StationType.Live]: LiveControls,
  [Playback.StationType.Playlist]: PlaylistControls,
  [Playback.StationType.PlaylistRadio]: PlaylistRadioControls,
  [Playback.StationType.Podcast]: PodcastControls,
  [Playback.StationType.TopSongs]: TopSongsControls,
} as const;

export function ControlSet({ isFullScreen }: { isFullScreen?: boolean }) {
  const state = playback.useState();

  if (isNull(state.station)) {
    return null;
  }

  const Controls = controls[state.station.type];

  return (
    <Player.Controls data-test="player-controls">
      <Controls isFullScreen={isFullScreen} />
    </Player.Controls>
  );
}
