import * as Analytics from '@iheartradio/web.analytics';
import { loadScript, waitUntil } from '@iheartradio/web.utilities';
import { mergeObjects } from '@iheartradio/web.utilities/object';
import { useEffect } from 'react';

import { useConfig } from '~app/contexts/config';

import { useAnalytics } from './create-analytics';

declare global {
  interface Window {
    _satellite?: {
      track: (event: any) => void;
      getVisitorId?: () => string;
      visitorSessionCount?: () => number;
    };
    analyticsData: {
      global?: Record<string, any>;
      events?: any;
    };
    tracker: any;
  }
}

export function Adobe() {
  const config = useConfig();
  const analytics = useAnalytics();

  useEffect(() => {
    const unsubscribe = analytics.subscribe({
      async initialize(data) {
        if (!config.sdks.adobeAnalytics.dtmUrl) {
          console.error(`'config.sdks.adobeAnalytics.dtmUrl' is not set`);
          return;
        }

        await loadScript(config.sdks.adobeAnalytics.dtmUrl);
        await waitUntil(() => !!window._satellite);

        const { getVisitorId, visitorSessionCount } = window._satellite as {
          getVisitorId?: () => string;
          visitorSessionCount?: () => number;
        };

        const visitor = getVisitorId && getVisitorId();
        const date = new Date();
        const adobeVersion = window?.tracker?.version;

        let visitorId = null;

        try {
          visitorId = (
            visitor as { getMarketingCloudVisitorID?: () => string }
          )?.getMarketingCloudVisitorID?.();
        } catch (error) {
          console.error(`Error getting visitorId: ${error}`);
        }

        window.analyticsData = {
          global: {
            ...data,
            user: { ...data.user, visitorId },
            device: {
              ...data.device,
              ...(visitorSessionCount && {
                visitorSessionCount: visitorSessionCount(),
              }),
              reportedDate: date.getTime(),
            },
            ...(adobeVersion && { adobeVersion }),
          },
        };
      },

      track(payload) {
        if (!window._satellite) {
          return;
        }

        const { type, ...event } = payload;

        const trackType =
          type === Analytics.eventType.enum.PageView ?
            'trackPage'
          : 'trackAction';

        const currentEvent = { [type]: event.data };

        window.analyticsData.events =
          !window.analyticsData.events ?
            currentEvent
          : mergeObjects(window.analyticsData?.events, currentEvent);

        // We need to update the global data as some device info eg: isPlaying gets updated
        if (window.analyticsData.global?.device && event.device) {
          window.analyticsData.global.device = {
            ...window.analyticsData.global.device,
            ...event.device,
          };
        }

        window.analyticsData = {
          global: { ...window.analyticsData.global },
          events: {
            ...window.analyticsData?.events,
            active: {
              action: type,
              ...event.data,
            },
          },
        };

        window._satellite.track(trackType);
      },
    });

    return unsubscribe;
  }, [analytics, config.sdks?.adobeAnalytics.dtmUrl]);

  return null;
}
