import { Slot } from '@radix-ui/react-slot';
import { type ForwardedRef, type PropsWithChildren, forwardRef } from 'react';

import {
  type RainbowSprinkles,
  rainbowSprinkles,
} from '../../rainbow-sprinkles.css.js';
import type { ElementProps } from '../../types.js';

export interface BoxProps
  extends PropsWithChildren<ElementProps<'div'> & RainbowSprinkles> {
  asChild?: boolean | undefined;
}

function Box(
  { asChild, children, ...props }: BoxProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const Component = asChild ? Slot : 'div';
  const { className, style, otherProps } = rainbowSprinkles(props);

  return (
    <Component className={className} ref={ref} style={style} {...otherProps}>
      {children}
    </Component>
  );
}

export const _Box = forwardRef(Box);
export { _Box as Box };
