import { Player } from '@iheartradio/web.companion';
import { Playback } from '@iheartradio/web.playback';
import { isNull } from '@iheartradio/web.utilities';
import { memo } from 'react';

import { playback } from '../playback';
import { AdsMetadata } from './ads-metadata';
import { AlbumMetadata } from './album-metadata';
import { ArtistMetadata } from './artist-metadata';
import { FavoritesMetadata } from './favorites-metadata';
import { LiveMetadata } from './live-metadata';
import { PlaylistMetadata } from './playlist-metadata';
import { PodcastMetadata } from './podcast-metadata';
import { TopSongsMetadata } from './top-songs-metadata';

type ContentMetadataProps = {
  kind: Playback.StationType | 'Ad';
};

const ContentMetadata = memo(function ContentMetadata({
  kind,
}: ContentMetadataProps) {
  switch (kind) {
    case Playback.StationType.Album: {
      return <AlbumMetadata />;
    }
    case Playback.StationType.Artist: {
      return <ArtistMetadata />;
    }
    case Playback.StationType.Favorites: {
      return <FavoritesMetadata />;
    }
    case Playback.StationType.Live: {
      return <LiveMetadata />;
    }
    case Playback.StationType.Playlist: {
      return <PlaylistMetadata />;
    }
    case Playback.StationType.PlaylistRadio: {
      return <PlaylistMetadata />;
    }
    case Playback.StationType.Podcast: {
      return <PodcastMetadata />;
    }
    case Playback.StationType.TopSongs: {
      return <TopSongsMetadata />;
    }
    case 'Ad': {
      return <AdsMetadata />;
    }
    default: {
      console.error(`Station kind "${kind} not recognized"`);
      return null;
    }
  }
});

export function Metadata() {
  const metadata = playback.useMetadata();
  const state = playback.useState();
  const { adBreak } = playback.useAds();

  if (isNull(state.station)) {
    return null;
  }

  return (
    <Player.Metadata data-test="player-metadata">
      <Player.Artwork
        alt="artwork"
        lazy={adBreak ? false : true}
        src={metadata?.data?.image}
      />
      <ContentMetadata kind={adBreak ? 'Ad' : state.station.type} />
    </Player.Metadata>
  );
}
