import { Playback } from '@iheartradio/web.playback';

import { Play } from './controls/play';
import type { RecSlide, StationRec } from './types';

const Titles = Object.freeze({
  Podcasts: 'Podcasts',
  Artists: 'Artists',
  Playlists: 'Playlists',
  Stations: 'Stations',
} as const);

const TitleMap = Object.freeze(
  new Map<Playback.StationType, string>([
    [Playback.StationType.Podcast, Titles.Podcasts],
    [Playback.StationType.Artist, Titles.Artists],
    [Playback.StationType.TopSongs, Titles.Artists],
    [Playback.StationType.Album, Titles.Artists],
    [Playback.StationType.Playlist, Titles.Playlists],
    [Playback.StationType.PlaylistRadio, Titles.Playlists],
    [Playback.StationType.Live, Titles.Stations],
    [Playback.StationType.Favorites, Titles.Stations],
  ]),
);

export const getContentTitle = (stationType: Playback.StationType): string => {
  return `${TitleMap.get(stationType)} You Might Like`;
};

export function getSlideDataFromStation(stationRec: StationRec): RecSlide {
  switch (stationRec.stationType) {
    case 'podcast': {
      return {
        stationId: stationRec.id,
        stationDescription: stationRec.description,
        stationTitle: stationRec.title,
        imageUrl: stationRec.imageUrl,
        imageButtonJSX: (
          <Play.Podcast context={0} id={Number(stationRec.id)} size={48} />
        ),
      };
    }
    case 'artist': {
      return {
        stationId: stationRec.id,
        stationTitle: stationRec.artistName,
        imageUrl: stationRec.image,
        imageButtonJSX: (
          <Play.Artist context={0} id={Number(stationRec.id)} size={48} />
        ),
      };
    }
    case 'playlist':
    case 'playlist-radio': {
      return {
        stationId: stationRec.id,
        stationDescription: stationRec.description,
        stationTitle: stationRec.name,
        imageUrl: stationRec.image,
        imageButtonJSX: (
          <Play.Playlist
            context={0}
            id={`${Number(stationRec?.userId)}::${stationRec.id}`}
            size={48}
          />
        ),
      };
    }
    case 'live': {
      return {
        stationId: stationRec.id,
        stationDescription: stationRec.description,
        stationTitle: stationRec.name,
        imageUrl: stationRec.logo,
        imageButtonJSX: (
          <Play.Live context={0} id={Number(stationRec.id)} size={48} />
        ),
      };
    }
    case 'favorites': {
      return {
        stationId: stationRec.id,
        stationDescription: stationRec.description,
        stationTitle: stationRec.name,
        imageUrl: stationRec.logo,
        imageButtonJSX: (
          <Play.Live context={0} id={Number(stationRec.id)} size={48} />
        ),
      };
    }
    default: {
      return {
        stationId: null,
        stationDescription: null,
        stationTitle: null,
        imageUrl: undefined,
        imageButtonJSX: null,
      };
    }
  }
}
