import { Player } from '@iheartradio/web.companion';
import { Playback } from '@iheartradio/web.playback';
import { isNull } from '@iheartradio/web.utilities';

import { useUser } from '~app/contexts/user';
import { isPremiumUser } from '~app/utilities/user';

import { playback } from '../playback';

const canShuffle = {
  [Playback.StationType.Album]: true,
  [Playback.StationType.Artist]: false,
  [Playback.StationType.Favorites]: false,
  [Playback.StationType.Live]: false,
  [Playback.StationType.Playlist]: true,
  [Playback.StationType.PlaylistRadio]: false,
  [Playback.StationType.Podcast]: false,
  [Playback.StationType.TopSongs]: true,
} as const;

export function Shuffle() {
  const player = playback.usePlayer();
  const state = playback.useState();
  const isPremium = isPremiumUser(useUser());

  if (isNull(state.station) || !canShuffle[state.station.type]) {
    return null;
  }

  return (
    <Player.Shuffle
      active={isPremium ? state.shuffled : true}
      onClick={() => player.setShuffle()}
    />
  );
}
