import { lightDark, vars } from '@iheartradio/web.accomplice';
import { Box } from '@iheartradio/web.accomplice/box';
import { Flex } from '@iheartradio/web.accomplice/flex';
import type React from 'react';
import { useMemo } from 'react';
import { ClientOnly } from 'remix-utils/client-only';

import { Footer } from '~app/components/footer';
import { Nav } from '~app/components/navigation';
import { UserEducationDialog } from '~app/components/user-education-dialog';
import { AppsFlyerStyles } from '~app/hooks/apps-flyer/apps-flyer-styles';
import {
  type AppsFlyerOffset,
  type AppsFlyerOffsetRem,
  useAppsFlyer,
} from '~app/hooks/apps-flyer/use-apps-flyer';
import { Player } from '~app/playback/player';

export type LayoutProps = {
  children: React.ReactNode;
  showUserEducationDialog?: boolean;
};

export const bannerContainerId = 'apps-flyer-container';

function getMainPaddingTop(offset: AppsFlyerOffset): AppsFlyerOffsetRem {
  const mainBasePaddingTop = 4.8;
  let additionalValue = 0;

  if (offset && offset.endsWith('rem')) {
    additionalValue = Number.parseFloat(offset);
  }

  const totalValue = mainBasePaddingTop + additionalValue;
  return offset ? `${totalValue}rem` : `${mainBasePaddingTop}rem`;
}

export function Layout({
  children,
  showUserEducationDialog = false,
}: LayoutProps) {
  const { bannerRef, appsFlyerOffset, appsFlyerSdk } = useAppsFlyer({
    sdkScriptId: 'apps-flyer-script-nav',
    bannerContainerId,
    view: {
      stacked: true,
    },
  });

  const mainPaddingTop = useMemo(
    () => getMainPaddingTop(appsFlyerOffset),
    [appsFlyerOffset],
  );

  return (
    <>
      <Box id={bannerContainerId} ref={bannerRef} width="100%">
        <ClientOnly>{() => <AppsFlyerStyles />}</ClientOnly>
      </Box>
      <UserEducationDialog showDialog={showUserEducationDialog} />
      <Nav appsFlyerOffset={appsFlyerOffset} />
      <Player appsFlyerSdk={appsFlyerSdk} />
      <Box
        asChild
        backgroundColor={lightDark(vars.color.gray100, vars.color.brandBlack)}
        data-test="main"
        id="main"
        minHeight="100dvh"
        paddingBottom={{ mobile: '12.4rem', large: '8.8rem' }}
        paddingLeft={{ mobile: 0, large: '31.6rem' }}
        paddingTop={{ mobile: mainPaddingTop, large: 0 }}
      >
        <main>
          <Flex flexDirection="column" flexGrow={1} width="100%">
            <Box flexGrow={0} height="0px" id="scroll-target" width="100%" />
            {children}
          </Flex>
          <Box alignSelf="stretch">
            <Footer />
          </Box>
        </main>
      </Box>
    </>
  );
}
