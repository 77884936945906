import { Play } from '../controls/play';
import { playback } from '../playback';

export function LiveControls() {
  // Pull the station off the state and add it to props here, so that the `useIsCurrentStation`
  // hook will behave correctly for the player controls
  const { station } = playback.useState();

  return <Play {...station} />;
}
