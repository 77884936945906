import { globalCss } from '@iheartradio/web.companion';
import { useEffect, useRef } from 'react';

function generateThemeStyles(theme: 'light' | 'dark') {
  const backgroundColor =
    theme === 'light' ?
      'var(--companion-colors-gray-150)'
    : 'var(--companion-colors-gray-550)';
  const textColor =
    theme === 'light' ?
      'var(--companion-colors-gray-600)'
    : 'var(--companion-colors-brand-white)';
  const buttonBackgroundColor =
    theme === 'light' ?
      'var(--companion-colors-gray-600)'
    : 'var(--companion-colors-brand-white)';
  const buttonTextColor =
    theme === 'light' ?
      'var(--companion-colors-brand-white)'
    : 'var(--companion-colors-gray-600)';

  return {
    // container background color
    [`[data-theme="${theme}"] #apps-flyer-container .responsive-wrapper`]: {
      backgroundColor: `${backgroundColor} !important`,
    },

    // title text color
    [`[data-theme="${theme}"] #apps-flyer-container [data-af-custom-fonts="af-creatives-text"]`]:
      {
        color: `${textColor} !important`,
      },

    // close icon inner color
    [`[data-theme="${theme}"] #apps-flyer-container [data-af-close-button="true"] svg path`]:
      {
        fill: `${textColor} !important`,
      },

    // cta button background color
    [`[data-theme="${theme}"] #apps-flyer-container .responsive-wrapper > div > div:last-child > div > div`]:
      {
        backgroundColor: `${buttonBackgroundColor} !important`,
      },

    // cta button text color
    [`[data-theme="${theme}"] #apps-flyer-container [data-af-cta-button="true"]`]:
      {
        color: `${buttonTextColor} !important`,
      },
  };
}

const styles = globalCss({
  // global font-family
  '[data-af-custom-fonts="af-creatives-text"]': {
    fontFamily: 'inherit !important',
  },
  ...generateThemeStyles('light'),
  ...generateThemeStyles('dark'),
});

export function AppsFlyerStyles() {
  const mounted = useRef(false);
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      styles();
    }
  }, []);
  return null;
}
