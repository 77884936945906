import { pxToRem } from '@iheartradio/web.companion';
import { useLocation } from '@remix-run/react';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useConfig } from '~app/contexts/config';

type AppsFlyerParams = {
  [key: string]: string | boolean;
};

type AppsFlyerAction = 'hideBanner' | 'showBanner' | 'updateParams';

declare global {
  interface Window {
    AF?: {
      (
        command: 'banners',
        action: Extract<AppsFlyerAction, 'updateParams'>,
        params: AppsFlyerParams,
      ): void;
      (
        command: 'banners',
        action: Extract<AppsFlyerAction, 'showBanner' | 'hideBanner'>,
        params?: {
          bannerContainerQuery?: string;
          bannerZIndex?: number;
          additionalParams?: AppsFlyerParams;
        },
      ): void;
    };
  }
}

interface AppsFlyerConfig {
  sdkScriptId: string;
  bannerContainerId: string;
  view?: {
    stacked?: boolean;
  };
  scroll?: {
    hide: boolean;
    targetEl?: string;
    pixels?: number;
  };
}

export type AppsFlyerOffsetRem = `${number}rem`;
export type AppsFlyerOffset = AppsFlyerOffsetRem | '0';

const executeBannerAction = (
  bannerContainerId: string,
  action: AppsFlyerAction,
  params?: AppsFlyerParams,
) => {
  if (window.AF) {
    if (action === 'updateParams' && params) {
      window.AF('banners', action as 'updateParams', params);
    } else {
      window.AF('banners', action as 'hideBanner' | 'showBanner', {
        bannerContainerQuery: `#${bannerContainerId}`,
      });
    }
  } else {
    console.warn(`AppsFlyer SDK not loaded, cannot execute ${action}`);
  }
};

export const getAppsFlyerSdk = (bannerContainerId: string) => ({
  hideBanner: () => executeBannerAction(bannerContainerId, 'hideBanner'),
  showBanner: () => executeBannerAction(bannerContainerId, 'showBanner'),
  updateParams: (params: AppsFlyerParams) =>
    executeBannerAction(bannerContainerId, 'updateParams', params),
});

export type AppsFlyerSdk = ReturnType<typeof getAppsFlyerSdk>;

export function useAppsFlyer({
  sdkScriptId,
  bannerContainerId,
  view = { stacked: false },
  scroll = { hide: false, targetEl: '', pixels: 0 },
}: AppsFlyerConfig) {
  const config = useConfig();
  const { key = '' } = config.sdks.appsFlyer ?? {};
  const location = useLocation();
  const [bannerContainer, setBannerContainer] = useState<HTMLDivElement | null>(
    null,
  );
  const bannerContainerRef = useRef<HTMLDivElement | null>(null);
  const [sdkLoaded, setSdkLoaded] = useState(false);
  const [bannerHidden, setBannerHidden] = useState(false);
  const [scrollOff, setScrollOff] = useState(false);
  const [flyerOffset, setFlyerOffset] = useState<AppsFlyerOffset>('0');
  const appsFlyerSdk = useMemo(
    () => getAppsFlyerSdk(bannerContainerId),
    [bannerContainerId],
  );

  /**
   * AF bootstrap script (recommended by AF) below creates a queue as a fallback
   * to ensure that the SDK is loaded before any commands are executed
   */
  useEffect(() => {
    const existingScript = document.querySelector(`#${sdkScriptId}`);
    if (existingScript) {
      console.info('AppsFlyer script already exists or script is in flight');
      return;
    }

    if (!key) {
      console.error('AppsFlyer key is missing');
      return;
    }

    setBannerContainer(bannerContainerRef.current);

    const script = document.createElement('script');
    script.id = sdkScriptId;

    script.innerHTML = `!function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){
      (t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},
      t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,
      o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),
      p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","banners",{banners: {key: "${key}"}});
      AF('banners', 'showBanner', { bannerContainerQuery: '#${bannerContainerId}' });`;

    document.body.append(script);

    return () => script.remove();
  }, [key]);

  /**
   * This approach is predicated on certain assumptions, including the banner being positioned at the top with the 'sticky' option activated.
   */
  useEffect(() => {
    const observer = new MutationObserver(mutations => {
      if (mutations.length === 2) {
        setSdkLoaded(true);
      }
      for (const mutation of mutations) {
        for (const node of mutation.addedNodes) {
          if (node instanceof HTMLElement && node.style.position !== 'fixed') {
            if (view.stacked) {
              setFlyerOffset(pxToRem(node.style.height, 1));
            }
            node.remove();
          }
          if (node instanceof HTMLElement && node.style.position === 'fixed') {
            const button = node.querySelector('button');
            if (button) {
              button.style.textAlign = 'center';
            }
          }
        }

        if (view.stacked) {
          for (const node of mutation.removedNodes) {
            if (
              node instanceof HTMLElement &&
              node.style.position === 'fixed'
            ) {
              setFlyerOffset('0');
              setScrollOff(true);
            }
          }
        }
      }
    });

    const observerConfig = { childList: true };
    if (bannerContainer) {
      observer.observe(bannerContainer, observerConfig);
    }

    return () => observer.disconnect();
  }, [bannerContainer]);

  /**
   * Scroll Implementation for a target element via both scroll directions; default is Window w/ 300px threshold
   */
  useEffect(() => {
    let initialScrollPosition = 0;
    const setupScroll =
      (element: Element | Window, pixels = 300) =>
      () => {
        const currentScrollPosition =
          element instanceof Window ? window.scrollY : element.scrollTop;

        if (initialScrollPosition === 0) {
          initialScrollPosition = currentScrollPosition;
          return;
        }

        const scrollDistance = Math.abs(
          initialScrollPosition - currentScrollPosition,
        );

        if (window.AF && scrollDistance > pixels) {
          appsFlyerSdk.hideBanner();
          setBannerHidden(true);
        }
      };

    const targetElement =
      scroll.targetEl ? document.querySelector(scroll.targetEl) : window;

    if (!targetElement) {
      return;
    }

    const handleScroll = setupScroll(targetElement, scroll.pixels);

    if (scroll.hide && targetElement) {
      if (bannerHidden || scrollOff) {
        targetElement.removeEventListener('scroll', handleScroll);
      } else if (sdkLoaded) {
        targetElement.addEventListener('scroll', handleScroll);
      }
    }

    return () => {
      if (targetElement) {
        targetElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [sdkLoaded, bannerHidden, scrollOff]);

  /**
   * Deep Linking Ref: https://support.appsflyer.com/hc/en-us/articles/115002366466
   * As of 12/13/23, we do not support universal/app links, so we need to force the custom uri scheme fallback to be used instead
   * Ref: https://support.appsflyer.com/hc/en-us/articles/360014821438-OneLink-troubleshooting-and-FAQ#which-operating-systems-allow-uri-schemes-to-open-the-app
   */
  useEffect(() => {
    if (sdkLoaded && window.AF) {
      appsFlyerSdk.updateParams({
        af_dp: `ihr://${
          deepLinkURIMapping[location.pathname] ?? deepLinkURIMapping['/']
        }`,
      });
    }
  }, [sdkLoaded, location.pathname]);

  return useMemo(
    () => ({
      bannerRef: bannerContainerRef,
      appsFlyerOffset: flyerOffset,
      appsFlyerSdk,
    }),
    [bannerContainerRef, flyerOffset, appsFlyerSdk],
  );
}

// TODO: all these routes should be added to an e2e test to intercept network reqs made by sdk after clicking the cta.. and ensure that af_dp is being passed in the query params w/ the current location path
const deepLinkURIMapping: {
  [key: string]: string;
} = {
  '/': '',
};
