import { Notification, Player, useToast } from '@iheartradio/web.companion';
import { Playback } from '@iheartradio/web.playback';

import { useUser } from '~app/contexts/user';
import { isPremiumUser } from '~app/utilities/user';

import { playback } from '../../playback/playback';

/**
 * A user generally won't have this much, but this upper limit is to make sure we don't show the
 * Number.MAX_SAFE_INTEGER amount that Amp will return by default in some cases.
 */
const MAX_SKIPS = 100;

export function Next({ ...props }) {
  const player = playback.usePlayer();
  const { adBreak } = playback.useAds();
  const state = playback.useState();
  const toast = useToast();
  const isPremium = isPremiumUser(useUser());

  function next() {
    if (
      !isPremium &&
      state.skips <= 0 &&
      state.station?.type !== Playback.StationType.Podcast
    ) {
      toast(onDismiss => (
        <Notification
          kind="info"
          onDismiss={onDismiss}
          title={`You've reached your skip limit.`}
        />
      ));

      return;
    }

    player.next();
  }

  return (
    <Player.Next
      data-test="next-player-button"
      {...props}
      disabled={adBreak}
      onClick={next}
      skips={
        (
          !isPremium &&
          state.skips < MAX_SKIPS &&
          state?.station?.type !== Playback.StationType.Podcast
        ) ?
          state.skips
        : undefined
      }
    />
  );
}
