import { Player } from '@iheartradio/web.companion';
import { Playback } from '@iheartradio/web.playback';

import { playback } from '../playback';

export function PlaybackSpeed() {
  const player = playback.usePlayer();
  const state = playback.useState();

  if (state.station?.type !== Playback.StationType.Podcast) {
    return null;
  }

  return (
    <Player.PlaybackSpeed triggerText={`${state.speed}x`}>
      <Player.MenuItem onSelect={() => player.setSpeed(Playback.Speed.Slow)}>
        0.5x
      </Player.MenuItem>
      <Player.MenuItem onSelect={() => player.setSpeed(Playback.Speed.Normal)}>
        1x
      </Player.MenuItem>
      <Player.MenuItem onSelect={() => player.setSpeed(Playback.Speed.Fast)}>
        1.25x
      </Player.MenuItem>
      <Player.MenuItem onSelect={() => player.setSpeed(Playback.Speed.Faster)}>
        1.5x
      </Player.MenuItem>
      <Player.MenuItem onSelect={() => player.setSpeed(Playback.Speed.Fastest)}>
        2x
      </Player.MenuItem>
    </Player.PlaybackSpeed>
  );
}
