import { Flex } from '@iheartradio/web.accomplice/flex';
import {
  MenuContent,
  MenuRoot,
  MenuTrigger,
} from '@iheartradio/web.accomplice/menu';
import { Text } from '@iheartradio/web.accomplice/text';
import {
  Button,
  CheckFilledIcon,
  ErrorFilledIcon,
  media,
  Notification,
  Player,
  Theme,
  ThemeProvider,
  useMediaQuery,
  useTheme,
  useToast,
} from '@iheartradio/web.companion';
import { isNull } from '@iheartradio/web.utilities';
import { Link as RouterLink } from '@remix-run/react';
import { useCallback, useState } from 'react';

import { AuthenticateCTANotification } from '~app/components/cta/authenticate-cta';
import type { CreatePlaylistSubmitCallback as CreateSubmitCallback } from '~app/components/dialogs/create-playlist-dialog';
import {
  type AddToCollectionSubmitCallback as AddSubmitCallback,
  AddToPlaylistSubMenu,
} from '~app/components/menu-items/add-to-playlist-sub-menu';
import { useUser } from '~app/contexts/user';
import {
  ADD_TO_PLAYLIST_AUTHENTICATION_MESSAGE,
  REG_GATE_TRIGGER_TYPES,
} from '~app/utilities/constants';
import { buildPlaylistUrl } from '~app/utilities/urls';

import { playback } from '../playback';

export function AddToPlaylist() {
  const user = useUser();
  const toast = useToast();
  const { station } = playback.useState();
  const metadata = playback.useMetadata();
  const isLarge = useMediaQuery(media.large);
  const [isFullScreen, setIsFullScreen] = Player.useFullScreen();
  const defaultTheme = useTheme();
  const theme = isFullScreen ? defaultTheme : Theme.Dark;
  // TODO: Refactor to a better solution - ref blur, ref focus, etc.
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const handleAddSubmit = useCallback<AddSubmitCallback>(data => {
    setMenuOpen(false);
    if (data?.ok) {
      toast(dismiss => (
        <Notification
          icon={<CheckFilledIcon fill="green-600" />}
          kind="success"
          onDismiss={dismiss}
        >
          Added to {data.collection.name}
          <Flex
            alignItems="center"
            flexDirection="row"
            gap="$8"
            justifyContent="flex-end"
          >
            <Button
              as={RouterLink}
              color="gray"
              kind="tertiary"
              onClick={() => setIsFullScreen(false)}
              size="large"
              to={buildPlaylistUrl(data.collection)}
            >
              Go to Playlist
            </Button>
          </Flex>
        </Notification>
      ));
    }

    if (!data?.ok) {
      toast(dismiss => (
        <Notification
          icon={<ErrorFilledIcon fill="red-650" />}
          kind="error"
          onDismiss={dismiss}
        >
          These songs are already added
          <Text kind="body-4">
            We can&apos;t add duplicate songs to a playlist.
          </Text>
        </Notification>
      ));
    }
  }, []);

  const handleCreateSubmit = useCallback<CreateSubmitCallback>(
    (data, closeDialog) => {
      closeDialog();
      setMenuOpen(false);

      if (data.ok) {
        toast(dismiss => (
          <Notification
            icon={<CheckFilledIcon fill="green-600" />}
            kind="success"
            onDismiss={dismiss}
          >
            Playlist created
            <Flex
              alignItems="center"
              flexDirection="row"
              gap="$8"
              justifyContent="flex-end"
            >
              <Button
                as={RouterLink}
                color="gray"
                kind="tertiary"
                onClick={() => setIsFullScreen(false)}
                size="large"
                to={buildPlaylistUrl(data.collection)}
              >
                Go to Playlist
              </Button>
            </Flex>
          </Notification>
        ));
      }
    },
    [setIsFullScreen, toast],
  );

  if (isNull(station) || isNull(metadata)) {
    return null;
  }

  const { trackId, id } = metadata.data;

  if (user?.isAnonymous) {
    return (
      <Player.AddToPlaylist
        data-test="add-to-playlist-button"
        onClick={() => {
          toast(dismiss => (
            <AuthenticateCTANotification
              dismiss={dismiss}
              trigger={REG_GATE_TRIGGER_TYPES.ADD_TO_PLAYLIST}
            >
              {ADD_TO_PLAYLIST_AUTHENTICATION_MESSAGE}
            </AuthenticateCTANotification>
          ));
        }}
      />
    );
  }

  // TODO: CHECK THIS!
  return (
    <MenuRoot open={menuOpen}>
      <MenuTrigger asChild>
        <Player.AddToPlaylist
          data-test="add-to-playlist-menu-trigger"
          onClick={() => setMenuOpen(!menuOpen)}
        />
      </MenuTrigger>
      <MenuContent
        align={!isLarge && isFullScreen ? 'end' : 'start'}
        data-test="add-to-playlist-menu"
        onInteractOutside={() => setMenuOpen(false)}
        style={{
          maxHeight: '40dvh',
        }}
      >
        <ThemeProvider value={theme}>
          <AddToPlaylistSubMenu
            onAddSubmit={handleAddSubmit}
            onCreateSubmit={handleCreateSubmit}
            tracks={[Number(trackId ?? id)]}
          />
        </ThemeProvider>
      </MenuContent>
    </MenuRoot>
  );

  // TODO: REMOVE THIS
  // return (
  //   <Menu
  //     align={!isLarge && isFullScreen ? 'end' : 'start'}
  //     data-test="add-to-playlist-menu"
  //     maxHeight="40dvh"
  //     onInteractOutside={() => setMenuOpen(false)}
  //     open={menuOpen}
  //     trigger={
  //       <Player.AddToPlaylist
  //         data-test="add-to-playlist-menu-trigger"
  //         onClick={() => setMenuOpen(!menuOpen)}
  //       />
  //     }
  //   >
  //     <ThemeProvider value={theme}>
  //       <AddToPlaylistSubMenu
  //         onAddSubmit={handleAddSubmit}
  //         onCreateSubmit={handleCreateSubmit}
  //         tracks={[Number(trackId ?? id)]}
  //       />
  //     </ThemeProvider>
  //   </Menu>
  // );
}
