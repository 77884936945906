import { media, useMediaQuery } from '@iheartradio/web.companion';

import { Back15 } from '../controls/back-15';
import { Forward30 } from '../controls/forward-30';
import { Next } from '../controls/next';
import { Play } from '../controls/play';
import { Previous } from '../controls/previous';
import { playback } from '../playback';

export function PodcastControls({ isFullScreen }: { isFullScreen?: boolean }) {
  const isLargeScreen = useMediaQuery(media.large);
  const shouldShow = isFullScreen || isLargeScreen;

  // Pull the station off the state and add it to props here, so that the `useIsCurrentStation`
  // hook will behave correctly for the player controls
  const { station } = playback.useState();

  return (
    <>
      {shouldShow ?
        <Previous />
      : null}
      <Back15 />
      <Play {...station} />
      <Forward30 />
      {shouldShow ?
        <Next />
      : null}
    </>
  );
}
