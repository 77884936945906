import { fontSizes } from '../core/font-sizes.js';

export function findBaseFontSize(fontSizesMap: typeof fontSizes) {
  for (const [px, rem] of Object.entries(fontSizesMap)) {
    if (rem === '1.0rem') {
      return Number.parseInt(px, 10);
    }
  }
}

export function pxToRem(px: string, offset = 0): `${number}rem` {
  const baseFontSize = findBaseFontSize(fontSizes);
  const pxValue = Number.parseFloat(px) - offset;
  return `${pxValue / (baseFontSize ?? 10)}rem`;
}
