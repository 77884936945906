import { Player } from '@iheartradio/web.companion';
import { Playback } from '@iheartradio/web.playback';
import { isNull } from '@iheartradio/web.utilities';
import { Link as RouterLink } from '@remix-run/react';
import { memo, useState } from 'react';

import { useClick } from '~app/analytics/use-click';
import { useFollowUnfollow } from '~app/hooks/use-follow-unfollow/use-follow-unfollow';
import { useGetPageName } from '~app/hooks/use-get-page-name';
import {
  buildAlbumUrl,
  buildArtistUrl,
  buildLiveUrl,
  buildPlaylistUrl,
  buildPodcastEpisodeUrl,
  buildPodcastUrl,
} from '~app/utilities/urls';

import { playback } from '../playback';

const canGoToArtist = new Set([
  Playback.StationType.Album,
  Playback.StationType.Live,
  Playback.StationType.Artist,
  Playback.StationType.Favorites,
  Playback.StationType.Podcast,
  Playback.StationType.Playlist,
  Playback.StationType.PlaylistRadio,
  Playback.StationType.TopSongs,
]);

const canGoToAlbum = new Set([
  Playback.StationType.Album,
  Playback.StationType.Artist,
  Playback.StationType.Favorites,
  Playback.StationType.Playlist,
  Playback.StationType.PlaylistRadio,
  Playback.StationType.TopSongs,
]);

const canGoToPlaylist = new Set([
  Playback.StationType.Playlist,
  Playback.StationType.PlaylistRadio,
]);

const FollowPodcast = memo(function FollowPodcast({
  podcastId,
  podcastName,
  setIsMenuOpen,
}: {
  podcastId: number;
  podcastName: string;
  setIsMenuOpen?: (open: boolean) => void;
}) {
  const { isFollowing, follow, unfollow } = useFollowUnfollow({
    contentId: String(podcastId),
    contentType: Playback.StationType.Podcast,
    contentName: podcastName,
    section: 'player',
    onSuccess: () => {
      setIsMenuOpen?.(false);
    },
  });

  return isFollowing ?
      <Player.MenuItem
        onSelect={event => {
          event.preventDefault();
          unfollow();
        }}
      >
        Unfollow podcast
      </Player.MenuItem>
    : <Player.MenuItem
        onSelect={event => {
          event.preventDefault();
          follow();
        }}
      >
        Follow podcast
      </Player.MenuItem>;
});

const FollowPlaylist = memo(function FollowPlaylist({
  playlistId,
  playlistName,
  userId,
  setIsMenuOpen,
}: {
  playlistId: string;
  playlistName: string;
  userId: string;
  setIsMenuOpen?: (open: boolean) => void;
}) {
  const { isFollowing, follow, unfollow } = useFollowUnfollow({
    authorId: userId,
    contentId: playlistId,
    contentType: Playback.StationType.Playlist,
    contentName: playlistName,
    section: 'player',
    onSuccess: () => {
      setIsMenuOpen?.(false);
    },
  });

  return isFollowing ?
      <Player.MenuItem
        onSelect={event => {
          event.preventDefault();
          unfollow();
        }}
      >
        Unfollow playlist
      </Player.MenuItem>
    : <Player.MenuItem
        onSelect={event => {
          event.preventDefault();
          follow();
        }}
      >
        Follow playlist
      </Player.MenuItem>;
});

const FollowArtist = memo(function FollowArtist({
  artistId,
  artistName,
  setIsMenuOpen,
}: {
  artistId: string;
  artistName: string;
  setIsMenuOpen?: (open: boolean) => void;
}) {
  const { isFollowing, follow, unfollow } = useFollowUnfollow({
    contentId: artistId,
    contentType: Playback.StationType.Artist,
    contentName: artistName,
    section: 'player',
    onSuccess: () => {
      setIsMenuOpen?.(false);
    },
  });

  return isFollowing ?
      <Player.MenuItem
        onSelect={event => {
          event.preventDefault();
          unfollow();
        }}
      >
        Unfollow artist
      </Player.MenuItem>
    : <Player.MenuItem
        onSelect={event => {
          event.preventDefault();
          follow();
        }}
      >
        Follow artist
      </Player.MenuItem>;
});

const FollowLive = memo(function FollowLive({
  stationId,
  stationName,
  setIsMenuOpen,
}: {
  stationId: string;
  stationName: string;
  setIsMenuOpen?: (open: boolean) => void;
}) {
  const { isFollowing, follow, unfollow } = useFollowUnfollow({
    contentId: stationId,
    contentType: Playback.StationType.Live,
    contentName: stationName,
    section: 'player',
    onSuccess: () => {
      setIsMenuOpen?.(false);
    },
  });

  return isFollowing ?
      <Player.MenuItem
        onSelect={event => {
          event.preventDefault();
          unfollow();
        }}
      >
        Unfollow station
      </Player.MenuItem>
    : <Player.MenuItem
        onSelect={event => {
          event.preventDefault();
          follow();
        }}
      >
        Follow station
      </Player.MenuItem>;
});

export function Menu() {
  const state = playback.useState();
  const metadata = playback.useMetadata();
  const pageName = useGetPageName();
  const { onAnalyticsClick } = useClick();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  if (isNull(state.station) || isNull(metadata)) {
    return null;
  }

  const [userId, playlistId] = String(state.station.id).split('::');

  const { type } = state.station;

  const {
    id,
    artistId,
    artistName,
    albumId,
    albumName,
    podcastId,
    podcastSlug,
    followable,
    transcriptionAvailable,
    title,
    subtitle,
  } = metadata.data;

  const albumUrl =
    artistId && albumId && albumName ?
      buildAlbumUrl({
        artist: { id: artistId, name: artistName ?? '' },
        album: { id: albumId, name: albumName },
      })
    : undefined;
  const artistUrl =
    artistId ?
      buildArtistUrl({ id: artistId, name: artistName ?? '' })
    : undefined;
  const stationUrl = id ? buildLiveUrl({ name: title, id }) : undefined;
  const podcastUrl =
    podcastId && podcastSlug ?
      buildPodcastUrl({ podcastId, slug: podcastSlug })
    : undefined;
  const episodeUrl =
    id && podcastId && podcastSlug && subtitle ?
      buildPodcastEpisodeUrl({
        podcastId,
        podcastSlug,
        episodeId: id,
        episodeName: subtitle,
      })
    : undefined;
  const playlistUrl =
    playlistId && userId ?
      buildPlaylistUrl({
        name: title ?? '',
        userId,
        id: playlistId,
      })
    : undefined;

  return (
    <Player.Menu
      data-test="menu"
      onChange={open => setIsMenuOpen(open)}
      open={isMenuOpen}
    >
      {(
        (type === Playback.StationType.PlaylistRadio ||
          type === Playback.StationType.Playlist) &&
        followable &&
        playlistId
      ) ?
        <FollowPlaylist
          playlistId={playlistId}
          playlistName={subtitle ?? title ?? ''}
          setIsMenuOpen={setIsMenuOpen}
          userId={userId}
        />
      : null}
      {type === Playback.StationType.Artist && artistId ?
        <FollowArtist
          artistId={artistId}
          artistName={artistName}
          setIsMenuOpen={setIsMenuOpen}
        />
      : null}
      {type === Playback.StationType.Live && id ?
        <FollowLive
          setIsMenuOpen={setIsMenuOpen}
          stationId={id}
          stationName={title ?? ''}
        />
      : null}
      {type === Playback.StationType.Live && stationUrl ?
        <Player.Link as={RouterLink} to={stationUrl} underline="none">
          <Player.MenuItem>Go to station</Player.MenuItem>
        </Player.Link>
      : null}
      {canGoToPlaylist && playlistUrl ?
        <Player.Link as={RouterLink} to={playlistUrl} underline="none">
          <Player.MenuItem>Go to playlist</Player.MenuItem>
        </Player.Link>
      : null}
      {canGoToArtist.has(type) && artistUrl ?
        <Player.Link as={RouterLink} to={artistUrl} underline="none">
          <Player.MenuItem>Go to artist</Player.MenuItem>
        </Player.Link>
      : null}
      {canGoToAlbum.has(type) && albumUrl ?
        <Player.Link
          as={RouterLink}
          data-test="go-to-album-option"
          to={albumUrl}
          underline="none"
        >
          <Player.MenuItem>Go to album</Player.MenuItem>
        </Player.Link>
      : null}
      {type === Playback.StationType.Podcast && podcastId ?
        <FollowPodcast
          podcastId={podcastId}
          podcastName={subtitle ?? ''}
          setIsMenuOpen={setIsMenuOpen}
        />
      : null}
      {type === Playback.StationType.Podcast && podcastUrl ?
        <Player.Link as={RouterLink} to={podcastUrl} underline="none">
          <Player.MenuItem>Go to podcast</Player.MenuItem>
        </Player.Link>
      : null}
      {type === Playback.StationType.Podcast && episodeUrl ?
        <Player.Link as={RouterLink} to={episodeUrl} underline="none">
          <Player.MenuItem>View episode info</Player.MenuItem>
        </Player.Link>
      : null}
      {transcriptionAvailable && episodeUrl ?
        <Player.Link
          as={RouterLink}
          data-test="view-episode-transcript-option"
          onClick={() =>
            onAnalyticsClick(
              pageName,
              'miniplayer_overflow|transcription_option',
            )
          }
          preventScrollReset
          to={`${episodeUrl}?viewTranscription=true`}
          underline="none"
        >
          <Player.MenuItem>View episode transcript</Player.MenuItem>
        </Player.Link>
      : null}
    </Player.Menu>
  );
}
