import { PlayerVolume } from '../controls/volume';
import { playback } from '../playback';

export function Volume() {
  const player = playback.usePlayer();
  const { muted, volume } = playback.useState();

  return (
    <PlayerVolume
      muted={muted}
      onChangeEnd={value => {
        player.setVolume(value);
      }}
      onMute={() => player.setMute()}
      volume={volume}
    />
  );
}
