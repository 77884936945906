import { useUser } from '~app/contexts/user';
import { isPremiumUser } from '~app/utilities/user';

import { Next } from '../controls/next';
import { Play } from '../controls/play';
import { Previous } from '../controls/previous';
import { playback } from '../playback';

export function PlaylistRadioControls() {
  const isPremium = isPremiumUser(useUser());
  // Pull the station off the state and add it to props here, so that the `useIsCurrentStation`
  // hook will behave correctly for the player controls
  const { station } = playback.useState();

  return (
    <>
      <Previous disabled={isPremium ? false : true} />
      <Play {...station} />
      <Next />
    </>
  );
}
