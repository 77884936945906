import type { BaseConfig } from '@iheartradio/web.config';
import {
  isNotBlank,
  isUndefined,
  loadScript,
} from '@iheartradio/web.utilities';
import { useMemo } from 'react';

import type { ScriptDescriptor } from './types.js';

export type DisplayAdsScriptsConfig = {
  enabled?: boolean;
  enabledBidders?: BaseConfig['ads']['headerBidding']['enabledBidders'];
  sdks?: Pick<
    BaseConfig['sdks'],
    'amazon' | 'indexExchange' | 'liveramp' | 'moat' | 'rubicon'
  >;
  language?: string;
  parentOverride?: 'head' | 'body';
  privacyOptOut?: boolean;
};

const buildScriptDescriptors = (
  enabledBidders: DisplayAdsScriptsConfig['enabledBidders'],
  sdks: DisplayAdsScriptsConfig['sdks'],
  language: DisplayAdsScriptsConfig['language'] = 'en',
  privacyOptOut: DisplayAdsScriptsConfig['privacyOptOut'] = false,
): ScriptDescriptor[] => {
  const scriptDescriptors: ScriptDescriptor[] = [];

  if (
    isNotBlank(enabledBidders) &&
    isNotBlank(sdks) &&
    isNotBlank(language) &&
    !privacyOptOut
  ) {
    for (const bidder of enabledBidders) {
      switch (bidder) {
        case 'amazon': {
          if (isNotBlank(sdks.amazon) && isNotBlank(sdks.amazon.script)) {
            scriptDescriptors.push({
              src: sdks.amazon.script,
              id: 'aps',
              'data-test': 'ads-script-aps',
              location: 'head',
            });
          }
          break;
        }
        case 'indexExchange': {
          if (
            language.toLocaleLowerCase() === 'en' &&
            isNotBlank(sdks.indexExchange) &&
            isNotBlank(sdks.indexExchange.en)
          ) {
            scriptDescriptors.push({
              src: sdks.indexExchange.en,
              id: 'indexExchangeEN',
              'data-test': 'ads-script-indexExchangeEN',
              location: 'head',
            });
            break;
          }

          if (
            language.toLocaleLowerCase() === 'fr' &&
            isNotBlank(sdks.indexExchange) &&
            isNotBlank(sdks.indexExchange.fr)
          ) {
            scriptDescriptors.push({
              src: sdks.indexExchange.fr,
              id: 'indexExchangeFR',
              'data-test': 'ads-script-indexExchangeFR',
              location: 'head',
            });
            break;
          }
          break;
        }
        case 'liveRamp': {
          if (isNotBlank(sdks.liveramp) && isNotBlank(sdks.liveramp.script)) {
            scriptDescriptors.push({
              src: sdks.liveramp.script,
              id: 'liveRamp',
              'data-test': 'ads-script-liveRamp',
              location: 'head',
            });
          }
          break;
        }
        case 'rubicon': {
          if (isNotBlank(sdks.rubicon) && isNotBlank(sdks.rubicon.script)) {
            scriptDescriptors.push({
              src: sdks.rubicon.script,
              id: 'rubicon',
              'data-test': 'ads-script-rubicon',
              location: 'head',
            });
          }
        }
      }
    }
  }

  scriptDescriptors.push({
    id: 'gpt',
    'data-test': 'ads-script-gpt',
    src: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
    async: true,
    location: 'head',
  });

  return scriptDescriptors;
};

export const DisplayAdsScripts = ({
  enabled = true,
  enabledBidders,
  sdks,
  language,
  parentOverride,
  privacyOptOut = false,
}: DisplayAdsScriptsConfig) => {
  const isBrowser = !isUndefined(globalThis.window?.document);

  useMemo(() => {
    if (!isBrowser || !enabled) return;

    const scripts = buildScriptDescriptors(
      enabledBidders,
      sdks,
      language,
      privacyOptOut,
    );

    if (isNotBlank(parentOverride)) {
      for (const scriptDescriptor of scripts)
        scriptDescriptor.location = parentOverride;
    }

    for (const script of scripts) {
      const { src, ...rest } = script;
      loadScript(src, rest);
    }
  }, [
    isBrowser,
    enabled,
    enabledBidders,
    language,
    sdks,
    parentOverride,
    privacyOptOut,
  ]);

  return null;
};
