import { Player } from '@iheartradio/web.companion';

import { playback } from '../playback';

export function Back15({ disabled }: { disabled?: boolean }) {
  const player = playback.usePlayer();
  const { adBreak } = playback.useAds();
  const time = playback.useTime();

  return (
    <Player.Back15
      data-test="back-15-player-button"
      disabled={adBreak || disabled}
      onClick={() => {
        if (time.position < 16) {
          player.seek(0);
          return;
        }

        player.rewind(15);
      }}
    />
  );
}
