import { Player } from '@iheartradio/web.companion';

import { Menu } from './menu';
import { PlaybackSpeed } from './playback-speed';
import { Shuffle } from './shuffle';
import { Volume } from './volume';

export function Actions() {
  return (
    <Player.Actions>
      <PlaybackSpeed />
      <Shuffle />
      <Volume />
      <Menu />
      <Player.Expand />
    </Player.Actions>
  );
}
